.CitiesList-Container {
    padding: 0px 140px 0px 140px;
    margin-top: 37px;
  }
  
  .CitiesList-HeadContainer {
    display: flex;
    margin-bottom: 26px;
  }
  
  .CitiesList-HeadTitle {
    font-family: "Cairo", sans-serif !important;
    font-size: 25px !important;
    color: #393737;
    font-weight: 700 !important;
  }
  
  .CitiesList-ReorderContent {
    display: flex;
    flex-direction: column;
    padding: 19px 24px 32px 16px;
    border-radius: 0px 6px 6px 6px;
    border: solid 3px #3f7cdf;
    background-color: #fff;
    min-height: 40vh;
    position: relative;
  }
  
  .CitiesList-HeadContent {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
  }
  
  .CitiesList-SearchContainer {
    display: flex;
    flex-direction: row;
    width: 70%;
  }
  
  .CitiesList-ReorderContent .MuiPaper-root {
    border-radius: 6px !important;
    margin-bottom: 23px;
  }
  
  .CitiesList-Search .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    height: 60px;
    border-radius: 6px;
    border: solid 2px #eee;
    background-color: #e7f3fd;
  }
  
  .CitiesList-Search div .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: unset !important;
  }
  
  .CitiesList-Search div .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font-family: "Cairo", sans-serif !important;
    font-size: 20px !important;
    /* opacity: 0.6; */
    color: #393737;
    font-weight: 600 !important;
  }
  
  .CitiesList-ResetContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .CitiesList-Link {
    text-decoration: none !important;
    color: unset;
  }
  
  .CitiesList-ExportLink {
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    border: solid 2px #eee;
    background-color: #fff;
    text-decoration: none !important;
    align-items: center;
    padding: 12px 11px 12px 15px;
    margin-left: 6px;
    cursor: pointer;
    height: 32px;
  }
  
  .CitiesList-ExportIcon {
    width: 38px;
    height: 32px;
  }
  
  .CitiesList-ResetHeadTitle {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: #6c757d;
    font-weight: 700 !important;
  }
  
  .CitiesList-OrderImg {
    width: 57px;
    height: 75px;
  }
  
  .CitiesList-PaginationContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .CitiesList-PaginationContent ul li .MuiPaginationItem-page.Mui-selected {
    background-color: #3f7cdf !important;
    color: #fff !important;
    font-family: "Cairo" !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  
  .CitiesList-TimesContainer {
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    border: solid 2px #eee;
    background-color: rgba(57, 55, 55, 0.08);
    align-items: center;
    justify-content: center;
    min-height: 38px;
  }
  
  .CitiesList-TimesTitle {
    color: rgba(57, 55, 55, 0.5) !important;
    font-family: "Cairo" !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    margin-left: 5px !important;
  }
  
  .CitiesList-TimesCountitle {
    color: #32c95a !important;
    font-family: "Cairo" !important;
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  
  .CitiesList-StatusActionContainer {
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    border: solid 2px #eee;
    background-color: #32c95a;
    align-items: center;
    justify-content: center;
    min-height: 38px;
    cursor: pointer;
  }
  
  .CitiesList-StatusTitle {
    color: #fff !important;
    font-family: "Cairo" !important;
    font-size: 12px !important;
    font-weight: 700 !important;
  }
  
  .CitiesList-SearchContainer .MuiFormControl-root {
    margin-left: 0px;
  }
  
  .SearchInput {
    width: 100%;
  }
  
  .SearchInput .MuiOutlinedInput-input {
    background-color: #f7f9fc;
    color: rgba(168, 196, 229, 1);
    border-radius: 3px;
    font-family: "Cairo", sans-serif !important;
    font-size: 22px !important;
    font-weight: 400 !important;
    padding: 8.5px 8px !important;
  }
  
  .SearchInput .MuiOutlinedInput-notchedOutline {
    border: 1px solid #a8c4e5;
  }
  
  .CitiesList-ViewContainer {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .CitiesList-ViewContainer .MuiPopover-paper {
    width: 90% !important;
    background-color: #fff !important;
    border-radius: 18px !important;
    border: solid 1px #707070 !important;
    top: 50px !important;
    left: 70px !important;
  }
  
  .CitiesList-PaperView {
    background-color: #fff !important;
    border-radius: 18px !important;
    border: solid 1px #707070 !important;
    padding: 23px 57.4px 41px 49.1px !important;
  }
  
  .CitiesList-HeadNavContainer {
    display: flex;
    margin-bottom: 0px;
  }
  
  .CitiesList-UserNav {
    border: solid 3px #3f7cdf;
    border-radius: 6px 6px 0px 0px;
    border-bottom: unset;
    padding: 10px 30px;
    background-color: #3f7cdf;
    margin-right: 10px;
  }
  
  .CitiesList-UserNavTitle {
    display: flex;
    color: #fff !important;
    font-family: "Cairo" !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  
  .CitiesList-RolesNav {
    border: solid 3px #3f7cdf;
    border-radius: 6px 6px 0px 0px;
    border-bottom: unset;
    padding: 10px 30px;
  }
  
  .CitiesList-RolesNavTitle {
    display: flex;
    color: #3f7cdf !important;
    font-family: "Cairo" !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  
  .CitiesList-RolesNavLink {
    text-decoration: none !important;
  }
  
  .CitiesList-CitiesNavLink {
    text-decoration: none !important;
  }
  
  .CitiesList-Active {
    background-color: #00b437 !important;
    color: #fff !important;
    font-family: "Cairo" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    border-radius: 5px;
    padding: 4px 8px;
    text-transform: capitalize;
  }
  
  .CitiesList-pending {
    background-color: #ff5f59 !important;
    color: #fff !important;
    font-family: "Cairo" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    border-radius: 5px;
    padding: 4px 8px;
    text-transform: capitalize;
  }
  
  .CitiesList-Action {
    display: flex;
    flex-direction: row;
  }
  
  .CitiesList-EditButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Cairo" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #1777fb;
    margin-right: 7px;
    cursor: pointer;
  }
  
  .CitiesList-EditButton .MuiSvgIcon-root {
    font-size: 14px !important;
  }
  
  .CitiesList-ChangePasswordButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Cairo" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #1777fb;
    margin-right: 7px;
    cursor: pointer;
  }
  
  .CitiesList-ChangePasswordButton .MuiSvgIcon-root {
    font-size: 14px !important;
    margin-right: 3px;
  }
  
  .CitiesList-DeleteButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Cairo" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #ff5f59;
    margin-right: 7px;
    cursor: pointer;
  }
  
  .CitiesList-DeleteButton .MuiSvgIcon-root {
    font-size: 14px !important;
    margin-right: 1px;
  }
  
  .CitiesList-ActiveButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Cairo" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #00b437;
    cursor: pointer;
  }
  
  .CitiesList-ActiveButton .MuiSvgIcon-root {
    font-size: 14px !important;
    margin-right: 1px;
  }
  
  .CitiesList-DeactivateButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Cairo" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #ff5f59;
    cursor: pointer;
  }
  
  .CitiesList-DeactivateButton .MuiSvgIcon-root {
    font-size: 14px !important;
    margin-right: 1px;
  }
  
  .CitiesList-NewUserButton {
    font-family: "Cairo" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    background-color: #1777fb;
    color: #fff;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  }
  
  .CitiesList-StatusInput {
    width: 25%;
    margin-left: 15px !important;
  }
  
  .CitiesList-StatusInput div .MuiSelect-icon {
    color: #3f7cdf !important;
  }
  
  .CitiesList-Input .MuiFormLabel-root {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: rgba(57, 55, 55, 0.4) !important;
    font-weight: 600 !important;
  }
  
  .CitiesList-Input div .MuiOutlinedInput-notchedOutline {
    border-radius: 6px !important;
    border: solid 2px #eee !important;
  }
  
  .CitiesList-Input .MuiFormLabel-root.Mui-focused {
    color: #3f7cdf !important;
  }
  
  .CitiesList-Input div .MuiInputBase-input {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: rgba(57, 55, 55, 0.4) !important;
    font-weight: 600 !important;
    height: 22px;
  }
  
  .CitiesList-Input div .MuiInputBase-input:focus {
    color: #393737 !important;
  }
  
  .CitiesList-MenuItemDisabled {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: #393737 !important;
    font-weight: 600 !important;
  }
  
  .CitiesList-MenuItem {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: rgba(57, 55, 55, 0.4) !important;
    font-weight: 600 !important;
  
  }
  
  .CitiesList-SearchInput {
    width: 25%;
  }
  
  .CitiesList-NewUserContainer {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .CitiesList-NewUserContainer .MuiPopover-paper{
    width: 50% !important;
    background-color: #fff !important;
    border-radius: 8px !important;
    border: solid 1px #707070 !important;
    top: 25% !important;
    left: 0 !important;
    position: absolute;
    right: 0 !important;
    margin: auto;
    box-shadow: unset !important;
  }
  
  .UseresList-PaperNewUser {
    background-color: #fff !important;
    border-radius: 8px !important;
    border: solid 1px #707070 !important;
    padding:23px 57.4px 41px 49.1px !important;
  }
  
  .CitiesList-ChangePasswordContainer {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .CitiesList-ChangePasswordContainer .MuiPopover-paper{
    width: 50% !important;
    background-color: #fff !important;
    border-radius: 8px !important;
    border: solid 1px #707070 !important;
    top: 25% !important;
    left: 0 !important;
    position: absolute;
    right: 0 !important;
    margin: auto;
    box-shadow: unset !important;
  }
  
  .UseresList-PaperChangePassword {
    background-color: #fff !important;
    border-radius: 8px !important;
    border: solid 1px #707070 !important;
    padding:23px 57.4px 41px 49.1px !important;
  }
  
  .CitiesList-EditUserContainer {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .CitiesList-EditUserContainer .MuiPopover-paper{
    width: 50% !important;
    background-color: #fff !important;
    border-radius: 8px !important;
    border: solid 1px #707070 !important;
    top: 25% !important;
    left: 0 !important;
    position: absolute;
    right: 0 !important;
    margin: auto;
    box-shadow: unset !important;
  }
  
  .UseresList-PaperEditUser {
    background-color: #fff !important;
    border-radius: 8px !important;
    border: solid 1px #707070 !important;
    padding:23px 57.4px 41px 49.1px !important;
  }
  
  .CitiesList-OpenButton {
    background-color: #1777fb;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  }

  .CitiesList-OpenButton a {
    text-decoration: none !important;
    color: #fff !important;
    font-family: "Cairo" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
  }

  @media only screen and (min-width: 0px) and (max-width: 575px) {
    .CitiesList-Container {
      padding: 0px 35px 0px 35px;
    }
  
    .LogoContainer {
      padding: 25px 0px 0px 0px;
    }
  
    .LanguageContainer {
      padding: 25px 0px 0px 0px;
    }
  
    .AppContent {
      padding: 5px 25px 40px 25px;
    }
  }
  
  @media only screen and (min-width: 576px) and (max-width: 799px) {
    .CitiesList-Container {
      padding: 0px 35px 0px 35px;
    }
  }
  
  @media only screen and (min-width: 800px) and (max-width: 1023px) {
    .CitiesList-Container {
      padding: 0px 35px 0px 35px;
    }
  }
  
  @media only screen and (min-width: 1024px) and (max-width: 1199px) {
    .CitiesList-Container {
      padding: 0px 70px 0px 70px;
    }
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .CitiesList-Container {
      padding: 0px 70px 0px 70px;
    }
  }
  