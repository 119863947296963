.NewHotel-Container {
  padding: 0px 140px 0px 140px;
  margin-top: 37px;
}

.NewHotel-HeadContainer {
  display: flex;
  margin-bottom: 26px;
}

.NewHotel-HeadTitle {
  font-family: "Cairo", sans-serif !important;
  font-size: 25px !important;
  color: #393737;
  font-weight: 700 !important;
}

.NewHotel-Content {
  display: flex;
  flex-direction: column;
  padding: 19px 24px 32px 16px;
  border-radius: 0px 6px 6px 6px;
  border: solid 3px #3f7cdf;
  background-color: #fff;
  min-height: 40vh;
  position: relative;
}

.NewHotel-AccordionHeadTitle {
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  color: #393737 !important;
  font-weight: 600 !important;
}

.NewHotel-FormFooter {
  display: flex;
  margin-top: 5px;
  flex-direction: row-reverse;
}

.NewHotel-NewButton {
  font-family: "Cairo", sans-serif !important;
  font-size: 15px !important;
  color: #fff !important;
  font-weight: bold !important;
  background-color: #3f7cdf !important;
  padding: 12px 0px !important;
  border-radius: 6px !important;
  border: solid 2px #eee !important;
  width: 25% !important;
}

.NewHotel-BackLink {
  padding: 12px 0px !important;
  border-radius: 6px !important;
  border: solid 2px #6c757d !important;
  width: 25% !important;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px !important;
}

.NewHotel-BackLinkTitle {
  font-family: "Cairo", sans-serif !important;
  font-size: 15px !important;
  color: #6c757d !important;
  font-weight: bold !important;
}

.NewHotel-FormRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
}

.NewHotel-FormRow:last-of-type {
  margin-bottom: 0px;
}

.NewHotel-SelectInput div .MuiSelect-icon {
  color: #3f7cdf !important;
}

.NewHotel-SelectInput {
  width: 25%;
  margin-right: 15px !important;
}

div[lang="ar"] .NewHotel-SelectInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}

.NewHotel-SelectInput:last-of-type {
  width: 25%;
  margin-right: 0px !important;
}

.NewHotel-FormInputError .MuiFormHelperText-root.Mui-error {
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  color: #cf2528 !important;
  font-weight: 600 !important;
}

.NewHotel-FormInputError div .MuiOutlinedInput-notchedOutline {
  border: solid 2px #cf2528 !important;
}

.NewHotel-FormInputError .MuiFormHelperText-root {
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  color: #cf2528 !important;
  font-weight: 600 !important;
}

.NewHotel-Input .MuiFormLabel-root {
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  color: rgba(57, 55, 55, 0.4) !important;
  font-weight: 600 !important;
}

.NewHotel-Input div .MuiOutlinedInput-notchedOutline {
  border-radius: 6px !important;
  border: solid 2px #eee;
}

.NewHotel-Input .MuiFormLabel-root.Mui-focused {
  color: #3f7cdf !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #3f7cdf !important;
}

.NewHotel-Input div .MuiInputBase-input {
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  color: rgba(57, 55, 55, 0.4) !important;
  font-weight: 600 !important;
  height: 22px;
}

.NewHotel-Input div .MuiInputBase-input:focus {
  color: #393737 !important;
}

.NewHotel-InputRequired {
  color: #cf2528 !important;
  font-family: "Cairo", sans-serif !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.NewHotel-FormDetailsContainer {
  display: flex !important;
  flex-direction: column !important;
}

.NewHotel-BuildingNumberInput {
  width: 25%;
  margin-right: 15px !important;
}

div[lang="ar"] .NewHotel-BuildingNumberInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}

.NewHotel-StreetInput {
  width: 50%;
  margin-right: 15px !important;
}

div[lang="ar"] .NewHotel-StreetInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}

.NewHotel-DistrictInput {
  width: 25%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.NewHotel-FacilityPoboxInput {
  width: 30%;
  margin-right: 15px !important;
}

div[lang="ar"] .NewHotel-FacilityPoboxInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}

.NewHotel-FacilityPhoneInput {
  width: 35%;
  margin-right: 15px !important;
}

div[lang="ar"] .NewHotel-FacilityPhoneInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}

.NewHotel-FacilityEmailInput {
  width: 35%;
}

.NewHotel-FacilityClassificationInput {
  width: 30% !important;
}

.NewHotel-FacilityClassificationStartDateInput {
  width: 35%;
  margin-right: 15px !important;
}

div[lang="ar"] .NewHotel-FacilityClassificationStartDateInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}

.NewHotel-FacilityClassificationEndDateInput {
  width: 35%;
}

.NewHotel-BoxTabs {
  display: flex;
  flex-direction: column;
}

.NewHotel-BoxTabs-Tab.Mui-selected {
  color: #fff !important;
  background-color: #3f7cdf !important;
  border-radius: 6px 6px 0px 0px !important;
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border-bottom: unset !important;
  padding: 12px 16px !important;
}

.NewHotel-BoxTabs-Tab.Mui-selected:first-of-type {
  margin-right: unset !important;
}

div[lang="en"] .NewHotel-BoxTabs-Tab.Mui-selected:first-of-type {
  margin-left: unset !important;
}

.NewHotel-BoxTabs-Tab:first-of-type {
  margin-right: unset !important;
}

div[lang="en"] .NewHotel-BoxTabs-Tab:first-of-type {
  margin-left: unset !important;
}

.NewHotel-BoxTabs-Tab {
  color: #3f7cdf !important;
  border: 3px solid #3f7cdf !important;
  border-radius: 6px 6px 0px 0px !important;
  border-bottom: unset !important;
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  font-weight: bold !important;
  margin-right: 10px !important;
  padding: 12px 16px !important;
}

div[lang="en"] .NewHotel-BoxTabs-Tab {
  margin-right: unset !important;
  margin-left: 10px !important;
}

.NewHotel-BoxTabs div .css-19kzrtu {
  border: 3px solid #3f7cdf;
}

.NewHotel-TabPanel-Container {
  border: 3px solid #3f7cdf;
}

.PrivateTabIndicator-colorSecondary-9 {
  display: none !important;
}

.NewHotel-FacilityNameENInput {
  width: 34%;
  margin-right: 15px !important;
}


div[lang="ar"] .NewHotel-FacilityNameENInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}

.NewHotel-PlaceNameENInput {
  width: 33%;
  margin-right: 15px !important;
}

div[lang="ar"] .NewHotel-PlaceNameENInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}


.NewHotel-LocationENInput {
  width: 33%;
}

.NewHotel-DescriptionENInput {
  width: 100%;
}

.NewHotel-DescriptionENInput div .MuiInputBase-input {
  height: unset !important;
}

.NewHotel-FacilityNameARInput {
  width: 34%;
  margin-right: 15px !important;
}

div[lang="ar"] .NewHotel-FacilityNameARInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}

.NewHotel-PlaceNameARInput {
  width: 33%;
  margin-right: 15px !important;
}

div[lang="ar"] .NewHotel-PlaceNameARInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}

.NewHotel-LocationARInput {
  width: 33%;
}

.NewHotel-DescriptionARInput {
  width: 100%;
}

.NewHotel-DescriptionARInput div .MuiInputBase-input {
  height: unset !important;
}

.NewHotel-HotelCoordinatesContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #ddd !important;
  margin: 0;
  padding: 10px;
  position: relative;
  border-radius: 4px;
  background-color: #fbfbfb;
  padding-left: 10px !important;
  padding-top: 35px;
}

.NewHotel-HotelCoordinatesContainer:last-of-type {
  margin-top: 55px !important;
}

.NewHotel-HotelCoordinatesHead {
  margin-bottom: 0px;
  width: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 5px 5px 10px;
  background-color: #ffffff;
  position: absolute;
  top: -15%;
  display: flex;
}

.NewHotel-HotelCoordinatesHeadTitle {
  color: #393737 !important;
  font-family: "Cairo", sans-serif !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.NewHotel-MapContainer {
  display: flex;
  width: 50%;
}

.NewHotel-HotelLngInput div .MuiInputBase-input {
  background-color: #fff !important;
}

.NewHotel-HotelLatInput div .MuiInputBase-input {
  background-color: #fff !important;
}

.NewHotel-HotelLngInput {
  width: 100%;
  margin-top: 20px !important;
}

.NewHotel-HotelLatInput {
  width: 100%;
}

.NewHotel-HotelCoordinatesMarker {
  color: #cf2528 !important;
}

.NewHotel-HotelHaramCoordinatesMarker {
  color: #00b437 !important;
}

.NewHotel-PricingAmountInput {
  width: 25%;
  margin-right: 15px !important;
}

div[lang="ar"] .NewHotel-PricingAmountInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}

.NewHotel-RenewalCountInput {
  width: 25%;
  margin-right: 15px !important;
}

div[lang="ar"] .NewHotel-RenewalCountInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}

.NewHotel-FloorsCountInput {
  width: 25%;
  margin-right: 15px !important;
}

div[lang="ar"] .NewHotel-FloorsCountInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}

.NewHotel-DeluxeRoomsCountInput {
  width: 25%;
}

.NewHotel-RegularRoomsCountInput {
  width: 25%;
  margin-right: 15px !important;
}

div[lang="ar"] .NewHotel-RegularRoomsCountInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}

.NewHotel-RegularSuitesCountInput {
  width: 25%;
  margin-right: 15px !important;
}

div[lang="ar"] .NewHotel-RegularSuitesCountInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}

.NewHotel-DeluxeSuitesCountInput {
  width: 25%;
  margin-right: 15px !important;
}

div[lang="ar"] .NewHotel-DeluxeSuitesCountInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}

.NewHotel-ParkingCountInput {
  width: 25%;
}

.NewHotel-FacilityOwnerInput {
  width: 33%;
  margin-right: 15px !important;
}

div[lang="ar"] .NewHotel-FacilityOwnerInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}

.NewHotel-OperatorNameInput {
  width: 33%;
  margin-right: 15px !important;
}

div[lang="ar"] .NewHotel-OperatorNameInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}

.NewHotel-OperatorGenderInput {
  width: 34% !important;
}

.NewHotel-LicensingInvestorInput {
  width: 50%;
  margin-right: 15px !important;
}

div[lang="ar"] .NewHotel-LicensingInvestorInput {
  margin-left: 15px !important;
  margin-right: unset !important;
}

.NewHotel-FacilityLicensingStartDateInput {
  width: 50%;
}

.NewHotel-FeaturesContainer {
  display: grid !important;
  grid-template-columns: auto auto auto auto;
}

.NewHotel-FeaturesContent-Checkbox .MuiTypography-body1 {
  color: #3F4F61 !important;
  font-family: "Cairo", sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}

.NewHotel-UploadPhoto {
  font-family: "Cairo", sans-serif !important;
    font-size: 15px !important;
    color: #fff !important;
    font-weight: bold !important;
    background-color: #0acb33 !important;
    padding: 12px 12px !important;
    border-radius: 6px !important;
    border: solid 2px #eee !important;
    cursor: pointer;
}

.NewHotel-RemoveAllPhoto {
  font-family: "Cairo", sans-serif !important;
    font-size: 15px !important;
    color: #fff !important;
    font-weight: bold !important;
    background-color: #cf2528 !important;
    padding: 12px 12px !important;
    border-radius: 6px !important;
    border: solid 2px #eee !important;
    cursor: pointer;
}

.NewHotel-UpdatePhoto {
  font-family: "Cairo", sans-serif !important;
    font-size: 13px !important;
    color: #fff !important;
    font-weight: bold !important;
    background-color: #0acb33 !important;
    padding: 6px 6px !important;
    border-radius: 3px !important;
    border: solid 1px #eee !important;
    cursor: pointer;
}

.NewHotel-RemovePhoto {
  font-family: "Cairo", sans-serif !important;
    font-size: 13px !important;
    color: #fff !important;
    font-weight: bold !important;
    background-color: #cf2528 !important;
    padding: 6px 6px !important;
    border-radius: 3px !important;
    border: solid 1px #eee !important;
    cursor: pointer;
}

.NewHotel-Images {
  display: grid !important;
  grid-template-columns: auto auto auto auto;
}

.NewHotel-HotelCoordinatesColumn {
  display: flex;
  flex-direction: column;
  width: 50%;
}

@media only screen and (min-width: 0px) and (max-width: 575px) {
  .NewHotel-Container {
    padding: 0px 35px 0px 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 799px) {
  .NewHotel-Container {
    padding: 0px 35px 0px 35px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
  .NewHotel-Container {
    padding: 0px 35px 0px 35px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .NewHotel-Container {
    padding: 0px 70px 0px 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .NewHotel-Container {
    padding: 0px 70px 0px 70px;
  }
}
