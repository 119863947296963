.ToolbarContainer {
    background-color: #fff;
    display: flex;
    flex-direction: row-reverse;
    padding: 10px 140px 10px 140px;
    border-bottom: 1px solid #dee2e6;
}

.ToolbarSettingsContainer {
    width: 50%;
    height: 43px;
    display: flex;
    flex-direction: row-reverse;
}

.ToolbarLogoContainer {
    width: 50%;
    display: flex;
    flex-direction: row;
}

.Toolbar-Logo {
    width: 182px;
    height: 43px;
}

.Toolbar-Settings {
    height: 46px;
    width: 46px;
    border: 1px solid #1777fb;
    border-radius: 50%;
}

.ToolbarSettings-Current:hover {
    background-color: unset !important;
    box-shadow: unset !important;
}

.ToolbarSettings-SettingsMenuItemTitle {
    font-family: 'Cairo', sans-serif !important;
    font-size: 16px !important;
    color: #1777fb !important;
    font-weight: 600 !important;
}

.ToolbarLanguage {
    padding: 9px 16px;
    border: 1px solid #1777fb;
    display: flex;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
}

.ToolbarLanguageTitle {
    font-family: 'Cairo', sans-serif !important;
    font-size: 16px !important;
    color: #1777fb;
    font-weight: 600 !important;
}

.ToolbarSettings-MenuLink {
    text-decoration: none !important;
}

@media only screen and (min-width: 0px) and (max-width: 575px) {
    .ToolbarContainer {
        padding: 10px 35px 10px 35px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 799px) {
    .ToolbarContainer {
        padding: 10px 35px 10px 35px;
    }
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
    .ToolbarContainer {
        padding: 10px 35px 10px 35px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
    .ToolbarContainer {
        padding: 10px 70px 10px 70px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .ToolbarContainer {
        padding: 10px 70px 10px 70px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
}

@media only screen and (min-width: 1920px) and (max-width: 1999px) {
}
