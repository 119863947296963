.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cluster-marker img {
    width: 25px;
  }

.hotel-marker-container {
  background: none;
  border: none;
}

.hotel-marker-img {
  width: 50px;
}

.HotelMarker-HotelContainer {
  display: none;
}

.hotel-marker-container:hover .HotelMarker-HotelContainer, .hotel-selected .HotelMarker-HotelContainer {
  display: flex;
  flex-direction: row;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  padding: 10px 25px 10px 10px;
  border-radius: 3px;
  cursor: pointer;
  margin: 12px auto;
  position: relative;
  width: 300px;
  align-items: center;
  z-index: 1000 !important;
}

.HotelMarker-HotelImg {
  width: 90px;
  height: 115px;
  margin-right: 5px;
}

.HotelMarker-HotelContent {
  display: flex;
  flex-direction: column;
}

.HotelMarker-HotelContent-Title {
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  color: #6c757d;
  font-weight: 600 !important;
  margin-bottom: 3px !important;
}

.HotelMarker-HotelContent-LocationTitle {
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  color: #6c757d;
  font-weight: 400 !important;
  margin-bottom: 3px !important;
}

.HotelMarker-HotelContent-DistanceTitle {
  font-family: "Cairo", sans-serif !important;
  font-size: 13px !important;
  color: #6c757d;
  font-weight: 400 !important;
  margin-top: 3px !important;
}

.HotelMarker-HotelContent-DistanceValue {
  font-family: "Cairo", sans-serif !important;
  font-size: 13px !important;
  color: #6c757d;
  font-weight: 600 !important;
}

.HotelMarker-HotelAction {
  color: #6c757d;
}


.bounce {
	-webkit-animation: bounce 2s;
	        animation: bounce 2s;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
	0%,
	25%,
	50%,
	75%,
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-20px);
		        transform: translateY(-20px);
	}
	60% {
		-webkit-transform: translateY(-12px);
		        transform: translateY(-12px);
	}
}

@keyframes bounce {
	0%,
	25%,
	50%,
	75%,
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-20px);
		        transform: translateY(-20px);
	}
	60% {
		-webkit-transform: translateY(-12px);
		        transform: translateY(-12px);
	}
}
