.NewUserContainer {
  display: flex;
  flex-direction: column;
}

.NewUser-HeadContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
}

.NewUser-HeadTitle {
  font-family: "Cairo", sans-serif !important;
  font-size: 18px !important;
  color: #393737;
  font-weight: 600 !important;
}

.NewUserLine {
  height: 1px;
  opacity: 0.2;
  background-color: #393737;
}

.NewUserFormContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.NewUserFormContent {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.NewUserInput {
    margin-bottom: 15px !important;
}

.NewUserInput .MuiFormLabel-root {
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  color: rgba(57, 55, 55, 0.4) !important;
  font-weight: 600 !important;
}

.NewUserInput div .MuiOutlinedInput-notchedOutline {
  border-radius: 6px !important;
  border: solid 2px #eee !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #3f7cdf !important;
}

.NewUserInput .MuiFormLabel-root.Mui-focused {
  color: #3f7cdf !important;
}

.NewUserInput div .MuiInputBase-input {
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  color: rgba(57, 55, 55, 0.4) !important;
  font-weight: 600 !important;
}

.NewUserInput div .MuiInputBase-input:focus {
  color: #393737 !important;
}

.NewUserInputRequired {
  color: #cf2528 !important;
  font-family: "Cairo", sans-serif !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.MuiCheckbox-root {
  color: #eee !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #3f7cdf !important;
}

.NewUserFormFooter {
  display: flex;
  margin-top: 5px;
}

.NewUserNewUserButton {
  font-family: "Cairo", sans-serif !important;
  font-size: 15px !important;
  color: #fff !important;
  font-weight: bold !important;
  background-color: #3f7cdf !important;
  padding: 12px 0px !important;
  border-radius: 6px !important;
  border: solid 2px #eee !important;
  width: 100% !important;
}

.NewUserFormInputError .MuiFormHelperText-root.Mui-error {
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  color: #cf2528 !important;
  font-weight: 600 !important;
}

.NewUserFormInputError .MuiFormHelperText-root {
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  color: #cf2528 !important;
  font-weight: 600 !important;
}

.NewUser-CheckboxContainer {
    display: flex;
    flex-direction: column;
    border: solid 2px #eee !important;
    border-radius: 6px;
    padding: 2px 15px;
    margin-bottom: 15px;
}

.NewUser-CheckboxTitle {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: #b0afaf !important;
    font-weight: 600 !important;
}

.NewUser-Checkbox {
    display: flex !important;
    flex-direction: row !important;
}