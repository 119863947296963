.GroupsList-Container {
    padding: 0px 140px 0px 140px;
    margin-top: 37px;
  }
  
  .GroupsList-HeadContainer {
    display: flex;
    margin-bottom: 26px;
  }
  
  .GroupsList-HeadTitle {
    font-family: "Cairo", sans-serif !important;
    font-size: 25px !important;
    color: #393737;
    font-weight: 700 !important;
  }
  
  .GroupsList-ReorderContent {
    display: flex;
    flex-direction: column;
    padding: 19px 24px 32px 16px;
    border-radius: 0px 6px 6px 6px;
    border: solid 3px #3f7cdf;
    background-color: #fff;
    min-height: 40vh;
    position: relative;
  }

  div[lang="ar"] .GroupsList-ReorderContent {
    border-radius: 6px 0px 6px 6px;
  }
  
  .GroupsList-HeadContent {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
  }
  
  .GroupsList-SearchContainer {
    display: flex;
    flex-direction: row;
    width: 70%;
  }
  
  .GroupsList-ReorderContent .MuiPaper-root {
    border-radius: 6px !important;
    margin-bottom: 23px;
  }
  
  .GroupsList-Search .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    height: 60px;
    border-radius: 6px;
    border: solid 2px #eee;
    background-color: #e7f3fd;
  }
  
  .GroupsList-Search div .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: unset !important;
  }
  
  .GroupsList-Search div .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font-family: "Cairo", sans-serif !important;
    font-size: 20px !important;
    /* opacity: 0.6; */
    color: #393737;
    font-weight: 600 !important;
  }
  
  .GroupsList-ResetContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .GroupsList-Link {
    text-decoration: none !important;
    color: unset;
  }
  
  .GroupsList-ExportLink {
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    border: solid 2px #eee;
    background-color: #fff;
    text-decoration: none !important;
    align-items: center;
    padding: 12px 11px 12px 15px;
    margin-left: 6px;
    cursor: pointer;
    height: 32px;
  }
  
  .GroupsList-ExportIcon {
    width: 38px;
    height: 32px;
  }
  
  .GroupsList-ResetHeadTitle {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: #6c757d;
    font-weight: 700 !important;
  }
  
  .GroupsList-OrderImg {
    width: 57px;
    height: 75px;
  }
  
  .GroupsList-PaginationContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .GroupsList-PaginationContent ul li .MuiPaginationItem-page.Mui-selected {
    background-color: #3f7cdf !important;
    color: #fff !important;
    font-family: "Cairo" !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  
  .GroupsList-TimesContainer {
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    border: solid 2px #eee;
    background-color: rgba(57, 55, 55, 0.08);
    align-items: center;
    justify-content: center;
    min-height: 38px;
  }
  
  .GroupsList-TimesTitle {
    color: rgba(57, 55, 55, 0.5) !important;
    font-family: "Cairo" !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    margin-left: 5px !important;
  }
  
  .GroupsList-TimesCountitle {
    color: #32c95a !important;
    font-family: "Cairo" !important;
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  
  .GroupsList-StatusActionContainer {
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    border: solid 2px #eee;
    background-color: #32c95a;
    align-items: center;
    justify-content: center;
    min-height: 38px;
    cursor: pointer;
  }
  
  .GroupsList-StatusTitle {
    color: #fff !important;
    font-family: "Cairo" !important;
    font-size: 12px !important;
    font-weight: 700 !important;
  }
  
  .GroupsList-SearchContainer .MuiFormControl-root {
    margin-left: 0px;
  }
  
  .SearchInput {
    width: 100%;
  }
  
  .SearchInput .MuiOutlinedInput-input {
    background-color: #f7f9fc;
    color: rgba(168, 196, 229, 1);
    border-radius: 3px;
    font-family: "Cairo", sans-serif !important;
    font-size: 22px !important;
    font-weight: 400 !important;
    padding: 8.5px 8px !important;
  }
  
  .SearchInput .MuiOutlinedInput-notchedOutline {
    border: 1px solid #a8c4e5;
  }
  
  .GroupsList-ViewContainer {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .GroupsList-ViewContainer .MuiPopover-paper {
    width: 90% !important;
    background-color: #fff !important;
    border-radius: 18px !important;
    border: solid 1px #707070 !important;
    top: 50px !important;
    left: 70px !important;
  }
  
  .GroupsList-PaperView {
    background-color: #fff !important;
    border-radius: 18px !important;
    border: solid 1px #707070 !important;
    padding: 23px 57.4px 41px 49.1px !important;
  }
  
  .GroupsList-HeadNavContainer {
    display: flex;
    margin-bottom: 0px;
  }
  
  .GroupsList-UserNav {
    border: solid 3px #3f7cdf;
    border-radius: 6px 6px 0px 0px;
    border-bottom: unset;
    padding: 10px 30px;
    margin-right: 10px;
  }

  div[lang="ar"] .GroupsList-UserNav {
    margin-left: 10px;
    margin-right: unset;
  }
  
  .GroupsList-UserNavTitle {
    display: flex;
    color: #3f7cdf !important;
    font-family: "Cairo" !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  
  .GroupsList-RolesNav {
    border: solid 3px #3f7cdf;
    border-radius: 6px 6px 0px 0px;
    border-bottom: unset;
    padding: 10px 30px;
    background-color: #3f7cdf;
  }
  
  .GroupsList-RolesNavTitle {
    display: flex;
    color: #fff !important;
    font-family: "Cairo" !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  
  .GroupsList-RolesNavLink {
    text-decoration: none !important;
  }
  
  .GroupsList-UsersNavLink {
    text-decoration: none !important;
  }
  
  .GroupsList-Active {
    background-color: #00b437 !important;
    color: #fff !important;
    font-family: "Cairo" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    border-radius: 5px;
    padding: 4px 8px;
    text-transform: capitalize;
  }
  
  .GroupsList-pending {
    background-color: #ff5f59 !important;
    color: #fff !important;
    font-family: "Cairo" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    border-radius: 5px;
    padding: 4px 8px;
    text-transform: capitalize;
  }
  
  .GroupsList-Action {
    display: flex;
    flex-direction: row;
  }
  
  .GroupsList-EditButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Cairo" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #1777fb;
    margin-right: 7px;
    cursor: pointer;
  }
  
  .GroupsList-EditButton .MuiSvgIcon-root {
    font-size: 14px !important;
  }
  
  .GroupsList-ChangePasswordButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Cairo" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #1777fb;
    margin-right: 7px;
    cursor: pointer;
  }
  
  .GroupsList-ChangePasswordButton .MuiSvgIcon-root {
    font-size: 14px !important;
    margin-right: 3px;
  }
  
  .GroupsList-DeleteButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Cairo" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #ff5f59;
    margin-right: 7px;
    cursor: pointer;
  }
  
  .GroupsList-DeleteButton .MuiSvgIcon-root {
    font-size: 14px !important;
    margin-right: 1px;
  }
  
  .GroupsList-ActiveButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Cairo" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #00b437;
    cursor: pointer;
  }
  
  .GroupsList-ActiveButton .MuiSvgIcon-root {
    font-size: 14px !important;
    margin-right: 1px;
  }
  
  .GroupsList-DeactivateButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Cairo" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #ff5f59;
    cursor: pointer;
  }
  
  .GroupsList-DeactivateButton .MuiSvgIcon-root {
    font-size: 14px !important;
    margin-right: 1px;
  }
  
  .GroupsList-NewRoleButton {
    font-family: "Cairo" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    background-color: #1777fb;
    color: #fff;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  }
  
  .GroupsList-StatusInput {
    width: 25%;
    margin-left: 15px !important;
  }
  
  .GroupsList-StatusInput div .MuiSelect-icon {
    color: #3f7cdf !important;
  }
  
  .GroupsList-Input .MuiFormLabel-root {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: rgba(57, 55, 55, 0.4) !important;
    font-weight: 600 !important;
  }
  
  .GroupsList-Input div .MuiOutlinedInput-notchedOutline {
    border-radius: 6px !important;
    border: solid 2px #eee !important;
  }
  
  .GroupsList-Input .MuiFormLabel-root.Mui-focused {
    color: #3f7cdf !important;
  }
  
  .GroupsList-Input div .MuiInputBase-input {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: rgba(57, 55, 55, 0.4) !important;
    font-weight: 600 !important;
    height: 22px;
  }
  
  .GroupsList-Input div .MuiInputBase-input:focus {
    color: #393737 !important;
  }
  
  .GroupsList-MenuItemDisabled {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: #393737 !important;
    font-weight: 600 !important;
  }
  
  .GroupsList-MenuItem {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: rgba(57, 55, 55, 0.4) !important;
    font-weight: 600 !important;
  
  }
  
  .GroupsList-SearchInput {
    width: 25%;
  }

  .GroupsList-Accordion {
    border: 1px solid #eee !important;
    box-shadow: unset !important;
  }
  
  .GroupsList-Accordion .MuiAccordionSummary-root .MuiAccordionSummary-content {
    justify-content: space-between;
  }

  .GroupsList-NewRoleContainer {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .GroupsList-NewRoleContainer .MuiPopover-paper{
    width: 50% !important;
    background-color: #fff !important;
    border-radius: 8px !important;
    border: solid 1px #707070 !important;
    top: 0 !important;
    left: 0 !important;
    position: absolute;
    right: 0 !important;
    margin: auto;
    box-shadow: unset !important;
  }
  
  .GroupsList-PaperNewRole {
    background-color: #fff !important;
    border-radius: 8px !important;
    border: solid 1px #707070 !important;
    padding:23px 57.4px 41px 49.1px !important;
  }
  
  .GroupsList-EditRoleContainer {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .GroupsList-EditRoleContainer .MuiPopover-paper{
    width: 50% !important;
    background-color: #fff !important;
    border-radius: 8px !important;
    border: solid 1px #707070 !important;
    top: 0 !important;
    left: 0 !important;
    position: absolute;
    right: 0 !important;
    margin: auto;
    box-shadow: unset !important;
  }
  
  .GroupsList-PaperEditRole {
    background-color: #fff !important;
    border-radius: 8px !important;
    border: solid 1px #707070 !important;
    padding:23px 57.4px 41px 49.1px !important;
  }

  .GroupsList-NoData {
    font-family: "Cairo", sans-serif !important;
    font-size: 20px !important;
    color: #ff5f59 !important;
    font-weight: 600 !important;
  }
  
  
  @media only screen and (min-width: 0px) and (max-width: 575px) {
    .GroupsList-Container {
      padding: 0px 35px 0px 35px;
    }
  
    .LogoContainer {
      padding: 25px 0px 0px 0px;
    }
  
    .LanguageContainer {
      padding: 25px 0px 0px 0px;
    }
  
    .AppContent {
      padding: 5px 25px 40px 25px;
    }
  }
  
  @media only screen and (min-width: 576px) and (max-width: 799px) {
    .GroupsList-Container {
      padding: 0px 35px 0px 35px;
    }
  }
  
  @media only screen and (min-width: 800px) and (max-width: 1023px) {
    .GroupsList-Container {
      padding: 0px 35px 0px 35px;
    }
  }
  
  @media only screen and (min-width: 1024px) and (max-width: 1199px) {
    .GroupsList-Container {
      padding: 0px 70px 0px 70px;
    }
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .GroupsList-Container {
      padding: 0px 70px 0px 70px;
    }
  }
  