.EditRoleContainer {
    display: flex;
    flex-direction: column;
  }
  
  .EditRole-HeadContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  
  .EditRole-HeadTitle {
    font-family: "Cairo", sans-serif !important;
    font-size: 18px !important;
    color: #393737;
    font-weight: 600 !important;
  }
  
  .EditRoleLine {
    height: 1px;
    opacity: 0.2;
    background-color: #393737;
  }
  
  .EditRoleFormContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .EditRoleFormContent {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
  }
  
  .EditRoleInput {
      margin-bottom: 15px !important;
  }
  
  .EditRoleInput .MuiFormLabel-root {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: rgba(57, 55, 55, 0.4) !important;
    font-weight: 600 !important;
  }
  
  .EditRoleInput div .MuiOutlinedInput-notchedOutline {
    border-radius: 6px !important;
    border: solid 2px #eee !important;
  }
  
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #3f7cdf !important;
  }
  
  .EditRoleInput .MuiFormLabel-root.Mui-focused {
    color: #3f7cdf !important;
  }
  
  .EditRoleInput div .MuiInputBase-input {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: rgba(57, 55, 55, 0.4) !important;
    font-weight: 600 !important;
  }
  
  .EditRoleInput div .MuiInputBase-input:focus {
    color: #393737 !important;
  }
  
  .EditRoleInputRequired {
    color: #cf2528 !important;
    font-family: "Cairo", sans-serif !important;
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  
  .MuiCheckbox-root {
    color: #eee !important;
  }
  
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #3f7cdf !important;
  }
  
  .EditRoleFormFooter {
    display: flex;
    margin-top: 5px;
  }
  
  .EditRoleEditRoleButton {
    font-family: "Cairo", sans-serif !important;
    font-size: 15px !important;
    color: #fff !important;
    font-weight: bold !important;
    background-color: #3f7cdf !important;
    padding: 12px 0px !important;
    border-radius: 6px !important;
    border: solid 2px #eee !important;
    width: 100% !important;
  }
  
  .EditRoleFormInputError .MuiFormHelperText-root.Mui-error {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: #cf2528 !important;
    font-weight: 600 !important;
  }
  
  .EditRoleFormInputError .MuiFormHelperText-root {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: #cf2528 !important;
    font-weight: 600 !important;
  }
  
  .EditRole-CheckboxContainer {
      display: flex;
      flex-direction: column;
      border: solid 2px #eee !important;
      border-radius: 6px;
      padding: 2px 15px;
      margin-bottom: 15px;
  }
  
  .EditRole-CheckboxTitle {
      font-family: "Cairo", sans-serif !important;
      font-size: 14px !important;
      color: #b0afaf !important;
      font-weight: 600 !important;
  }
  
  .EditRole-Checkbox {
      display: flex !important;
      flex-direction: row !important;
  }

  .EditRole-HeadPermissionTitle {
    font-family: "Cairo", sans-serif !important;
    font-size: 14px !important;
    color: #3f7cdf !important;
    font-weight: 600 !important;
  }