.ViewHotel-Container {
  padding: 0px 140px 0px 140px;
  margin-top: 37px;
}

.ViewHotel-ViewContent {
  display: flex;
  flex-direction: column;
  padding: 19px 24px 32px 16px;
  border-radius: 0px 6px 6px 6px;
  border: solid 3px #3f7cdf;
  background-color: #fff;
  min-height: 40vh;
  position: relative;
}

.ViewHotel-HeadContent {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}

.ViewHotel-NextHotelButton {
  font-family: "Cairo" !important;
  font-size: 11px !important;
  font-weight: 600 !important;
  border: 1px solid #1777fb;
  background-color: #1777fb;
  color: #fff;
  border-radius: 5px;
  padding: 6px 20px;
  cursor: pointer;
}

.ViewHotel-Link {
  text-decoration: none !important;
  color: unset;
}

.ViewHotel-PreviousHotelButton {
  font-family: "Cairo" !important;
  font-size: 11px !important;
  font-weight: 600 !important;
  background-color: #fff;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 5px;
  padding: 6px 20px;
  cursor: pointer;
}

.ViewHotel-UnVerifyHotelButton {
  font-family: "Cairo" !important;
  font-size: 11px !important;
  font-weight: 600 !important;
  border: 1px solid #ff5f59;
  background-color: #ff5f59;
  color: #fff;
  border-radius: 5px;
  padding: 6px 20px;
  cursor: pointer;
}

.ViewHotel-VerifyHotelButton {
  font-family: "Cairo" !important;
  font-size: 11px !important;
  font-weight: 600 !important;
  border: 1px solid #00b437;
  background-color: #00b437;
  color: #fff;
  border-radius: 5px;
  padding: 6px 20px;
  cursor: pointer;
}

.ViewHotel-HotelActionContainer {
  display: flex;
  flex-direction: row;
  width: 33%;
  justify-content: flex-end;
  gap: 4px;
  align-items: center;
}

.ViewHotel-HotelJumpContainer {
  display: flex;
  flex-direction: row;
  width: 33%;
  align-items: center;
}

.ViewHotel-HotelNavContainer {
  display: flex;
  flex-direction: row;
  width: 33%;
}

.ViewHotel-HotelNav-Item {
  font-family: "Cairo" !important;
  font-size: 11px !important;
  font-weight: 600 !important;
  background-color: #d8dbde;
  border: 1px solid #f7f7f7;
  color: #686d71;
  border-radius: 5px;
  padding: 6px 18px;
  cursor: pointer;
}

.ViewHotel-HotelNav-Item:hover {
  border: 1px solid #007bff;
  color: #007bff;
}

.ViewHotel-Input .MuiFormLabel-root {
  font-family: "Cairo", sans-serif !important;
  font-size: 11px !important;
  color: rgba(57, 55, 55, 0.4) !important;
  font-weight: 600 !important;
}

.ViewHotel-Input div .MuiOutlinedInput-notchedOutline {
  border-radius: 0px 5px 5px 0px !important;
  border: 1px solid #a0a3a5 !important;
}

div[lang="ar"] .ViewHotel-Input div .MuiOutlinedInput-notchedOutline {
  border-radius: 5px 0px 0px 5px !important;
}

.ViewHotel-Input .MuiFormLabel-root.Mui-focused {
  color: #3f7cdf !important;
}

.ViewHotel-Input div .MuiInputBase-input {
  font-family: "Cairo", sans-serif !important;
  font-size: 11px !important;
  color: rgba(57, 55, 55, 0.4) !important;
  font-weight: 600 !important;
  height: 7px;
  padding: 14px 14px !important;
}

.ViewHotel-Input div .MuiInputBase-input:focus {
  color: #393737 !important;
}

.ViewHotel-HotelJumpButton {
  font-family: "Cairo", sans-serif !important;
  font-size: 11px !important;
  font-weight: 700 !important;
  border: 1px solid #a0a3a5;
  border-radius: 5px 0px 0px 5px;
  color: #686d71;
  padding: 6px 20px;
  border-right: none;
  cursor: pointer;
}

div[lang="ar"] .ViewHotel-HotelJumpButton {
  border-radius: 0px 5px 5px 0px;
  border-left: none ;
  border-right: 1px solid #a0a3a5;
}

.ViewHotel-HotelJumpIDTitle {
  font-family: "Cairo", sans-serif !important;
  font-size: 11px !important;
  font-weight: 700 !important;
  color: #686d71;
  margin-left: 10px !important;
}

div[lang="ar"] .ViewHotel-HotelJumpIDTitle {
  margin-right: 10px !important;
}


.ViewHotel-DetailsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.ViewHotel-DetailsHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  border-bottom: 2px solid #d9d9d9;
  padding-bottom: 5px;
}

.ViewHotel-DetailsHeadTitle {
  font-family: "Cairo", sans-serif !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #1777fb;
}

.ViewHotel-DetailsHeadButton {
  font-family: "Cairo" !important;
  font-size: 11px !important;
  font-weight: 600 !important;
  border: 1px solid #007bff;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  padding: 6px 20px;
  cursor: pointer;
}

.ViewHotel-DetailsContent {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.ViewHotel-DetailsColumn {
  display: flex;
  flex-direction: column;
  width: 33%;
  gap: 10px;
}

.ViewHotel-DetailsRow {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.ViewHotel-DetailsRow-Label {
  font-family: "Cairo", sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #324356;
}

.ViewHotel-DetailsRow-title {
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #324356;
}

.ViewHotel-BoxTabs {
  display: flex;
  flex-direction: column;
}

.ViewHotel-BoxTabs-Tab.Mui-selected {
  color: #fff !important;
  background-color: #3f7cdf !important;
  border-radius: 6px 6px 0px 0px !important;
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border-bottom: unset !important;
  padding: 12px 16px !important;
}

.ViewHotel-BoxTabs-Tab.Mui-selected:first-of-type {
  margin-right: unset !important;
}

div[lang="en"] .ViewHotel-BoxTabs-Tab.Mui-selected:first-of-type {
  margin-left: unset !important;
}

.ViewHotel-BoxTabs-Tab:first-of-type {
  margin-right: unset !important;
}

div[lang="en"] .ViewHotel-BoxTabs-Tab:first-of-type {
  margin-left: unset !important;
}

.ViewHotel-BoxTabs-Tab {
  color: #3f7cdf !important;
  border: 3px solid #3f7cdf !important;
  border-radius: 6px 6px 0px 0px !important;
  border-bottom: unset !important;
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  font-weight: bold !important;
  margin-right: 10px !important;
  padding: 12px 16px !important;
}

div[lang="en"] .ViewHotel-BoxTabs-Tab {
  margin-right: unset !important;
  margin-left: 10px !important;
}

.ViewHotel-BoxTabs div .MuiBox-root {
  border: 3px solid #3f7cdf;
}

.ViewHotel-BoxTabs .MuiTabs-scroller .MuiTabs-indicator {
  display: none !important;
}

.ViewHotel-BoxTabs-Container {
  margin-top: 15px !important;
}

.ViewHotel-MapLocationContainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.ViewHotel-MapLocationHead {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    border-bottom: 2px solid #d9d9d9;
    padding-bottom: 5px;
}

.ViewHotel-MapLocationHeadTitle {
    font-family: "Cairo", sans-serif !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #1777fb;
}

.ViewHotel-MapLocationHeadButton {
    font-family: "Cairo" !important;
    font-size: 11px !important;
    font-weight: 600 !important;
    border: 1px solid #007bff;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    padding: 6px 20px;
    cursor: pointer;
}

.ViewHotel-MapLocationContent {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    gap: 20px;
}

.ViewHotel-MapLocation-Map {
    display: flex;
    width: 50%;
}

.ViewHotel-MapLocation-Coordinate {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 5px;
}

.ViewHotel-FacilitiesGrid {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 20px;
}

.ViewHotel-PhotosGrid {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 20px;
}

.ViewHotel-DoneIcon {
    color: #00b437 !important;
}

.ViewHotel-CloseIcon {
    color: #ff5f59 !important; 
}

.ViewHotel-Photo {
    width: 100px;
}

.ViewHotel-NextHotelButton-disabled {
  font-family: "Cairo" !important;
  font-size: 11px !important;
  font-weight: 600 !important;
  border: 1px solid #1777fb;
  background-color: #1777fb;
  color: #fff;
  border-radius: 5px;
  padding: 6px 20px;
  opacity: .65;
  pointer-events: none;
}


.ViewHotel-PreviousHotelButton-disabled {
  font-family: "Cairo" !important;
  font-size: 11px !important;
  font-weight: 600 !important;
  background-color: #fff;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 5px;
  padding: 6px 20px;
  opacity: .65;
  pointer-events: none;
}

@media only screen and (min-width: 0px) and (max-width: 575px) {
  .ViewHotel-Container {
    padding: 0px 35px 0px 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 799px) {
  .ViewHotel-Container {
    padding: 0px 35px 0px 35px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
  .ViewHotel-Container {
    padding: 0px 35px 0px 35px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .ViewHotel-Container {
    padding: 0px 70px 0px 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .ViewHotel-Container {
    padding: 0px 70px 0px 70px;
  }
}
