.HeaderContainer {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    padding: 10px 140px 0px 140px;
    border-bottom: 1px solid #dee2e6;
}

.Header-LinksList {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0px;
    margin: 0px;
    padding-right: 30px;
}

div[lang="ar"] .Header-LinksList {
    padding-right: 0px;
}

.Header-LinksItem {
    text-decoration: none !important;
    margin-right: 30px;
}

div[lang="ar"] .Header-LinksItem:first-of-type {
    margin-right: 0px;
}

.Header-LinksItem-active {
    text-decoration: none !important;
    margin-right: 30px;
    border-bottom: 1px solid #1777fb;
}

div[lang="ar"] .Header-LinksItem-active:first-of-type {
    margin-right: 0px;
}

.Header-LinksItemLink {
    text-decoration: none !important;
}

.Header-LinksItemLink-active {
    text-decoration: none !important;
}

.HeadersLinksItemTitle {
    font-family: 'Cairo', sans-serif !important;
    font-size: 16px !important;
    color: #343a40;
    font-weight: 600 !important;
}

.Header-LinksItemTitle-active {
    font-family: 'Cairo', sans-serif !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #1777fb;
}

@media only screen and (min-width: 0px) and (max-width: 575px) {
    .HeaderContainer {
        padding: 10px 35px 0px 35px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 799px) {
    .HeaderContainer {
        padding: 10px 35px 0px 35px;
    }
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
    .HeaderContainer {
        padding: 10px 35px 0px 35px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
    .HeaderContainer {
        padding: 10px 70px 0px 70px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .HeaderContainer {
        padding: 10px 70px 0px 70px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
}

@media only screen and (min-width: 1920px) and (max-width: 1999px) {
}
