.Home-Favorites-Line {
    height: 1px;
    width: 90%;
    margin-right: 70px;
    margin-bottom: 39px;
    background-color: rgba(112, 112, 112, 0.3);
}

.Home-Welcome {
    color: #393737 !important;
    font-family: "Cairo" !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    margin: auto;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    position: absolute;
    width: 100px;
    height: 100px;
}

@media only screen and (min-width: 0px) and (max-width: 575px) {
}

@media only screen and (min-width: 576px) and (max-width: 799px) {
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
}

@media only screen and (min-width: 1920px) and (max-width: 1999px) {
}
