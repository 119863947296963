.HotelsMap-Container {
  padding: 0px 140px 0px 140px;
  margin-top: 37px;
}

.HotelsMap-Content {
  display: flex;
  flex-direction: row;
  min-height: 80vh;
  height: 80vh;
  border-radius: 0px 6px 6px 6px;
  border: solid 3px #3f7cdf;
  background-color: #fff;
}

.HotelsMap-FilterContainer {
  background-color: #f9f9f9;
  width: 20%;
  padding: 10px;
}

.HotelsMap-ListContainer {
  -webkit-transition: -webkit-transform ease .2s;
  transition: transform ease .2s;
  background: #f9f9f9;
  -webkit-box-shadow: 0 0 24px rgb(0 0 0 / 15%);
  box-shadow: 0 0 24px rgb(0 0 0 / 15%);
  width: 30%;
  padding: 10px;
}

.HotelsMap-MapContainer {
  width: 50%;
}

.HotelsMap-FilterContent {
  background-color: #fff;
  border: 1px solid #bdbdbd;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 3px;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.HotelsMap-FilterHead {
  display: flex;
  flex-direction: row;
  padding: 8px;
  justify-content: space-between;
  border-bottom: 1px solid #bdbdbd;
}

.HotelsMap-FilterHeadTitle {
  font-family: "Cairo", sans-serif !important;
  font-size: 16px !important;
  color: #6c757d;
  font-weight: 700 !important;
}

.HotelsMap-ResetContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.HotelsMap-ResetTitle {
  font-family: "Cairo", sans-serif !important;
  font-size: 13px !important;
  color: #3f7cdf;
  font-weight: 600 !important;
}

.HotelsMap-ResetIcon {
  width: 13px !important;
  color: #3f7cdf;
}

.HotelsMap-FilterSearch {
  display: flex;
  flex-direction: column;
  padding: 16px 8px 8px;
}

.HotelsMap-Input .MuiFormLabel-root {
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  color: rgba(57, 55, 55, 0.4) !important;
  font-weight: 600 !important;
}

.HotelsMap-Input div .MuiOutlinedInput-notchedOutline {
  border-radius: 6px !important;
  border: solid 2px #eee !important;
}

.HotelsMap-Input .MuiFormLabel-root.Mui-focused {
  color: #3f7cdf !important;
}

.HotelsMap-Input div .MuiInputBase-input {
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  color: rgba(57, 55, 55, 0.4) !important;
  font-weight: 600 !important;
  height: 22px;
}

.HotelsMap-Input div .MuiInputBase-input:focus {
  color: #393737 !important;
}

.HotelsMap-FilterFacilityType {
  display: flex;
  flex-direction: column;
  padding: 16px 8px 8px;
}

.HotelsMap-FilterFacilityTypeTitle {
  font-family: "Cairo", sans-serif !important;
  font-size: 15px !important;
  color: #6c757d;
  font-weight: 600 !important;
}

.HotelsMap-FilterFacilityType-Checkbox {
  display: flex;
  flex-direction: column;
}

.HotelsMap-FilterFacilityType-Label .MuiTypography-body1 {
  font-family: "Cairo", sans-serif !important;
  font-size: 13px !important;
  color: #6c757d;
  font-weight: 600 !important;
}

.HotelsMap-FilterCity {
  display: flex;
  flex-direction: column;
  padding: 16px 8px 8px;
}

.HotelsMap-FilterCityTitle {
  font-family: "Cairo", sans-serif !important;
  font-size: 15px !important;
  color: #6c757d;
  font-weight: 600 !important;
}

.HotelsMap-MenuItemDisabled {
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  color: #393737 !important;
  font-weight: 600 !important;
}

.HotelsMap-MenuItem {
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  color: rgba(57, 55, 55, 0.4) !important;
  font-weight: 600 !important;
}

.HotelsMap-SelectInput div .MuiSelect-icon {
  color: #3f7cdf !important;
}

.HotelsMap-SelectInput {
  margin-top: 15px !important;
}

.HotelsMap-FilterArea {
  display: flex;
  flex-direction: column;
  padding: 16px 8px 8px;
}

.HotelsMap-FilterAreaTitle {
  font-family: "Cairo", sans-serif !important;
  font-size: 15px !important;
  color: #6c757d;
  font-weight: 600 !important;
}

.HotelsMap-FilterStarRating {
  display: flex;
  flex-direction: column;
  padding: 16px 8px 8px;
}

.HotelsMap-FilterDistanceFHaram {
  display: flex;
  flex-direction: column;
  padding: 16px 8px 8px;
}

.HotelsMap-FilterStarRatingTitle {
  font-family: "Cairo", sans-serif !important;
  font-size: 15px !important;
  color: #6c757d;
  font-weight: 600 !important;
}

.HotelsMap-FilterDistanceFHaramTitle {
  font-family: "Cairo", sans-serif !important;
  font-size: 15px !important;
  color: #6c757d;
  font-weight: 600 !important;
}

.HotelsMap-FilterStarRating-Checkbox {
  display: flex;
  flex-direction: column;
}

.HotelsMap-FilterStarRating-Label .MuiTypography-body1 {
  font-family: "Cairo", sans-serif !important;
  font-size: 13px !important;
  color: #6c757d;
  font-weight: 600 !important;
}

.HotelsMap-FilterDistanceFHaram-Checkbox {
  display: flex;
  flex-direction: column;
}

.HotelsMap-FilterDistanceFHaram-Label .MuiTypography-body1 {
  font-family: "Cairo", sans-serif !important;
  font-size: 13px !important;
  color: #6c757d;
  font-weight: 600 !important;
}

.HotelsMap-ListContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.HotelsMap-ListHead {
  display: flex;
  flex-direction: row;
  padding: 8px;
  justify-content: space-between;
}

.HotelsMap-ListHeadTitle {
  font-family: "Cairo", sans-serif !important;
  font-size: 16px !important;
  color: #6c757d;
  font-weight: 700 !important;
}

.HotelsMap-Lists {
  display: flex;
  flex-direction: column;
}

.HotelsMap-HotelContainer {
  display: flex;
  flex-direction: row;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  padding: 10px 25px 10px 10px;
  border-radius: 3px;
  cursor: pointer;
  margin: 12px auto;
  position: relative;
  width: 85%;
  align-items: center;
}

div[lang="ar"] .HotelsMap-HotelContainer {
  padding: 10px 10px 10px 25px;
}

.HotelsMap-HotelImg {
  width: 90px;
  height: 115px;
  margin-right: 5px;
}

div[lang="ar"] .HotelsMap-HotelImg {
  margin-left: 5px;
  margin-right: unset;
}

.HotelsMap-HotelContent {
  display: flex;
  flex-direction: column;
}

.HotelsMap-HotelContent-Title {
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  color: #6c757d;
  font-weight: 600 !important;
  margin-bottom: 3px !important;
}

.HotelsMap-HotelContent-LocationTitle {
  font-family: "Cairo", sans-serif !important;
  font-size: 14px !important;
  color: #6c757d;
  font-weight: 400 !important;
  margin-bottom: 3px !important;
}

.HotelsMap-HotelContent-DistanceTitle {
  font-family: "Cairo", sans-serif !important;
  font-size: 13px !important;
  color: #6c757d;
  font-weight: 400 !important;
  margin-top: 3px !important;
}

.HotelsMap-HotelContent-DistanceValue {
  font-family: "Cairo", sans-serif !important;
  font-size: 13px !important;
  color: #6c757d;
  font-weight: 600 !important;
}

.HotelsMap-HotelAction {
  color: #6c757d;
}

div[lang="ar"] .HotelsMap-HotelAction {
  transform: rotate(180deg);
}

div[lang="ar"] .HotelsMap-HotelContent {
  margin-left: 20px;
}

.HotelsMap-NoData {
  font-family: "Cairo", sans-serif !important;
  font-size: 18px !important;
  color: #ff5f59 !important;
  font-weight: 600 !important;
}

@media only screen and (min-width: 0px) and (max-width: 575px) {
  .HotelsMap-Container {
    padding: 0px 35px 0px 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 799px) {
  .HotelsMap-Container {
    padding: 0px 35px 0px 35px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
  .HotelsMap-Container {
    padding: 0px 35px 0px 35px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .HotelsMap-Container {
    padding: 0px 70px 0px 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .HotelsMap-Container {
    padding: 0px 70px 0px 70px;
  }
}
