.ForgotPasswordContainer {
    display: flex;
    flex-direction: row;
}

.ForgotPasswordFormContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.ForgotPasswordFormTitle {
    font-family: 'Cairo', sans-serif !important;
    font-size: 16px !important;
    color: #3f7cdf;
    font-weight: 600 !important;
    margin-bottom: 27px !important;
}

.ForgotPasswordFormContent {
    display: flex;
    flex-direction: column;
}

.ForgotPasswordEmailInput {
    width: 90%;
    margin-bottom: 23px !important;
}

.ForgotPasswordInput .MuiFormLabel-root {
    font-family: 'Cairo', sans-serif !important;
    font-size: 14px !important;
    color: rgba(57, 55, 55, 0.40) !important;
    font-weight: 600 !important;
}

.ForgotPasswordInput div .MuiOutlinedInput-notchedOutline {
    border-radius: 6px !important;
    border: solid 2px #eee !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #3F7CDF !important;
}

.ForgotPasswordInput .MuiFormLabel-root.Mui-focused {
    color: #3F7CDF !important;
}

.ForgotPasswordInput div .MuiInputBase-input {
    font-family: 'Cairo', sans-serif !important;
    font-size: 14px !important;
    color: rgba(57, 55, 55, 0.4) !important;
    font-weight: 600 !important;
}

.ForgotPasswordInput div .MuiInputBase-input:focus {
    color: #393737 !important;
}

.ForgotPasswordInputRequired {
    color: #CF2528 !important;
    font-family: 'Cairo', sans-serif !important;
    font-size: 20px !important;
    font-weight: 600 !important;
}

.ForgotPasswordFormFooter {
    display: flex;
    flex-direction: column;
    margin-top: 45px;
    margin-bottom: 133px;
}

.ForgotPasswordFormButton {
    font-family: 'Cairo', sans-serif !important;
    font-size: 19px !important;
    color: #fff !important;
    font-weight: bold !important;
    background-color: #1777fb !important;
    padding: 12px 0px !important;
    border-radius: 6px !important;
    border: solid 2px #eee !important;
    width: 90% !important;
}

.ForgotPasswordFormInputError .MuiFormHelperText-root.Mui-error {
    font-family: 'Cairo', sans-serif !important;
    font-size: 14px !important;
    color: #CF2528 !important;
    font-weight: 600 !important;
}

.ForgotPasswordFormInputError .MuiFormHelperText-root {
    font-family: 'Cairo', sans-serif !important;
    font-size: 14px !important;
    color: #CF2528 !important;
    font-weight: 600 !important;
}

.ForgotPasswordShowContainer {
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #CAD3E6 100%) 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    padding-top: 50px;
    height: 100vh;
}

.ForgotPasswordShowImg {
    width: 100%;
}

div[lang='en'] .ForgotPasswordShowImg {
    transform: rotateY(180deg);
}

.ForgotPasswordShowBackError {
    font-family: 'Cairo', sans-serif !important;
    font-size: 14px !important;
    color: #CF2528 !important;
    font-weight: 600 !important;
}

.ForgotPasswordVisibilityBackError {
    visibility: hidden;
}

.ForgotPasswordContent {
    padding: 15vh;
}

.ForgotPasswordBackLogin {
    text-decoration: none !important;
    margin-top: 10px;
}

.ForgotPasswordBackLoginTitle {
    font-family: 'Cairo', sans-serif !important;
    font-size: 15px !important;
    color: #b0afaf !important;
    font-weight: 600 !important;
}

@media only screen and (min-width: 0px) and (max-width: 575px) {
}

@media only screen and (min-width: 576px) and (max-width: 799px) {
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
}

@media only screen and (min-width: 1920px) and (max-width: 1999px) {
}
