.VerificationCodeContainer {
    display: flex;
    flex-direction: row;
}

.VerificationCodeFormContainer {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.VerificationCodeFormTitle {
    font-family: 'Cairo', sans-serif !important;
    font-size: 16px !important;
    color: #3f7cdf;
    font-weight: 600 !important;
    margin-bottom: 27px !important;
}

.VerificationCodeFormContent {
    display: flex;
    flex-direction: column;
}

.VerificationCodeVerifyInput {
    width: 90%;
    margin-bottom: 23px !important;
}

.VerificationCodeInput .MuiFormLabel-root {
    font-family: 'Cairo', sans-serif !important;
    font-size: 14px !important;
    color: rgba(57, 55, 55, 0.40) !important;
    font-weight: 600 !important;
}

.VerificationCodeInput div .MuiOutlinedInput-notchedOutline {
    border-radius: 6px !important;
    border: solid 2px #eee !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #3F7CDF !important;
}

.VerificationCodeInput .MuiFormLabel-root.Mui-focused {
    color: #3F7CDF !important;
}

.VerificationCodeInput div .MuiInputBase-input {
    font-family: 'Cairo', sans-serif !important;
    font-size: 14px !important;
    color: rgba(57, 55, 55, 0.4) !important;
    font-weight: 600 !important;
}

.VerificationCodeInput div .MuiInputBase-input:focus {
    color: #393737 !important;
}

/* Chrome, Safari, Edge, Opera */
.VerificationCodeVerifyInput div input::-webkit-outer-spin-button,
.VerificationCodeVerifyInput div input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.VerificationCodeVerifyInput div input[type="number"] {
  -moz-appearance: textfield;
}

.VerificationCodeInputRequired {
    color: #CF2528 !important;
    font-family: 'Cairo', sans-serif !important;
    font-size: 20px !important;
    font-weight: 600 !important;
}

.VerificationCodeFormFooter {
    display: flex;
    margin-top: 45px;
    margin-bottom: 133px;
}

.VerificationCodeFormButton {
    font-family: 'Cairo', sans-serif !important;
    font-size: 19px !important;
    color: #fff !important;
    font-weight: bold !important;
    background-color: #32c95a !important;
    padding: 12px 0px !important;
    border-radius: 6px !important;
    border: solid 2px #eee !important;
    width: 90% !important;
}

.VerificationCodeFormInputError .MuiFormHelperText-root.Mui-error {
    font-family: 'Cairo', sans-serif !important;
    font-size: 14px !important;
    color: #CF2528 !important;
    font-weight: 600 !important;
}

.VerificationCodeFormInputError .MuiFormHelperText-root {
    font-family: 'Cairo', sans-serif !important;
    font-size: 14px !important;
    color: #CF2528 !important;
    font-weight: 600 !important;
}

.VerificationCodeShowContainer {
    display: flex;
    width: 40%;
}

.VerificationCodeShowImg {
    width: 100%;
}

.VerificationCodeShowBackError {
    font-family: 'Cairo', sans-serif !important;
    font-size: 14px !important;
    color: #CF2528 !important;
    font-weight: 600 !important;
}

.VerificationCodeVisibilityBackError {
    visibility: hidden;
}

@media only screen and (min-width: 0px) and (max-width: 575px) {
}

@media only screen and (min-width: 576px) and (max-width: 799px) {
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
}

@media only screen and (min-width: 1920px) and (max-width: 1999px) {
}
